<template>
  <div
    v-if="
      getUser.role.slug === 'business-developer-pro' ||
        getUser.role.slug === 'super-administrateur'
    "
  >
    <b-card>
      <b-card-header>
        <h5>Filtrages</h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="10"
            lg="10"
            class="mb-md-0 mb-2"
          >
            <b-row>
              <b-col
                cols="12"
                sm="6"
                md="4"
                lg="4"
              >
                <label for="">Point Focal</label>
                <v-select
                  v-model="payloadFilter.pf_id"
                  :options="pointFocalOptions"
                  class="invoice-filter-select"
                  label="name"
                  :reduce="(pointFocal) => pointFocal.id"
                >
                  <template v-slot:no-options>
                    <b-spinner
                      v-if="isPointFocauxWihoutPagination"
                      style="width: 2.5rem; height: 2.5rem"
                      class="align-middle text-info"
                    />
                    <span
                      v-else
                      class="mt-1 font-medium-1"
                    >Aucun point focal ne correspond</span>
                  </template>
                </v-select>
              </b-col>
              <b-col
                cols="12"
                sm="6"
                md="2"
                lg="2"
                class="mt-2"
              >
                <b-button
                  variant="primary"
                  @click="applyFilterPointFocauxAction()"
                >
                  <div v-if="isReLoadFilterDataPointFocalWithoutPagination">
                    <span> Chargement ... </span>
                    <b-spinner small />
                  </div>

                  <span
                    v-else
                    class="text-nowrap font-medium-1"
                  >Filtrer</span>
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-card>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-2 mb-md-0"
          >
            <b-button
              v-b-modal.modal-new-offer-type
              variant="primary"
              class="mr-2"
            >
              <span class="text-nowrap font-medium-1">Ajouter</span>
            </b-button>

            <b-modal
              id="modal-new-offer-type"
              cancel-variant="outline-secondary"
              hide-footer
              centered
              no-close-on-backdrop
              no-close-on-esc
              title="Ajout d'un point focal"
            >
              <validation-observer
                #default="{}"
                ref="addNewRoleForm"
              >
                <!-- Form -->
                <b-form
                  class="p-2"
                  autocomplete="off"
                  @reset.prevent="hideModal(payload, 'modal-new-offer-type')"
                  @submit.prevent="applyAddNewPointFocalAction"
                >
                  <!-- Type de point focal -->
                  <validation-provider
                    #default="{ errors }"
                    name="is_center"
                    rules="required"
                  >
                    <b-form-group
                      label="Type de point focal"
                      label-for="is_center"
                    >
                      <b-form-select
                        v-model="payload.is_center"
                        :options="[
                          { value: 1, text: 'Centre de formation' },
                          { value: 0, text: 'Standard' },
                        ]"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>

                  <!-- Ville -->
                  <validation-provider
                    #default="{ errors }"
                    name="Ville"
                    rules="required"
                  >
                    <b-form-group
                      label="Ville"
                      label-for="town_name"
                    >
                      <b-form-input
                        id="town_name"
                        v-model="payload.town_name"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                        trim
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>

                  <!-- Email -->
                  <validation-provider
                    v-if="payload.is_center == true"
                    #default="{ errors }"
                    name="email"
                    rules="required"
                  >
                    <b-form-group
                      label="Email"
                      label-for="email"
                    >
                      <b-form-input
                        id="town_name"
                        v-model="payload.email"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                        trim
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>

                  <validation-provider
                    #default="{ errors }"
                    name="Nom du Responsable"
                    rules="required"
                  >
                    <b-form-group
                      label="Nom du Responsable"
                      label-for="responsable_name"
                    >
                      <b-form-input
                        id="responsable_name"
                        v-model="payload.responsable_name"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                        trim
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>

                  <!-- Form Actions -->
                  <div class="d-flex mt-2">
                    <b-button
                      :disabled="isAddPointFocalLoading"
                      variant="primary"
                      class="mr-2"
                      type="submit"
                    >
                      <div v-if="isAddPointFocalLoading">
                        <span> Chargement ... </span>
                        <b-spinner small />
                      </div>
                      <span v-else>Enregistrer</span>
                    </b-button>
                    <b-button
                      type="reset"
                      variant="outline-secondary"
                    >
                      Annuler
                    </b-button>
                  </div>
                </b-form>
              </validation-observer>
            </b-modal>

            <b-modal
              id="modal-update-offer-type"
              cancel-variant="outline-secondary"
              hide-footer
              centered
              no-close-on-backdrop
              no-close-on-esc
              title="Modification du Point Focal"
            >
              <validation-observer
                #default="{}"
                ref="updatePointFocalForm"
              >
                <!-- Form -->
                <b-form
                  class="p-2"
                  autocomplete="off"
                  @reset.prevent="hideModal(payload, 'modal-update-offer-type')"
                  @submit.prevent="applyUpdateOnePointFocalAction"
                >
                  <!-- Type de point focal -->
                  <validation-provider
                    #default="{ errors }"
                    name="is_center"
                    rules="required"
                  >
                    <b-form-group
                      label="Type de point focal"
                      label-for="is_center"
                    >
                      <b-form-select
                        v-model="payload.is_center"
                        :options="[
                          { value: 1, text: 'Centre de formation' },
                          { value: 0, text: 'Standard' },
                        ]"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>



                  <!-- Email -->
                  <validation-provider
                    v-if="payload.is_center == true"
                    #default="{ errors }"
                    name="email"
                    rules="required"
                  >
                    <b-form-group
                      label="Email"
                      label-for="email"
                    >
                      <b-form-input
                        id="town_name"
                        v-model="payload.email"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                        trim
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>

                  <!-- ville -->
                  <validation-provider
                    #default="{ errors }"
                    name="Ville"
                    rules="required"
                  >
                    <b-form-group
                      label="Ville"
                      label-for="town_name"
                    >
                      <b-form-input
                        id="town_name"
                        v-model="payload.town_name"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                        trim
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>

                  <validation-provider
                    #default="{ errors }"
                    name="Nom du Responsable"
                    rules="required"
                  >
                    <b-form-group
                      label="Nom du Responsable"
                      label-for="responsable_name"
                    >
                      <b-form-input
                        id="responsable_name"
                        v-model="payload.responsable_name"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                        placeholder=""
                        rows="3"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>

                  <!-- Form Actions -->
                  <div class="d-flex mt-2">
                    <b-button
                      :disabled="isAddPointFocalLoading"
                      variant="primary"
                      class="mr-2"
                      type="submit"
                    >
                      <div v-if="isUpdatePointFocalLoading">
                        <span> Chargement ... </span>
                        <b-spinner small />
                      </div>
                      <span v-else>Modifier</span>
                    </b-button>
                    <b-button
                      type="reset"
                      variant="outline-secondary"
                    >
                      Annuler
                    </b-button>
                  </div>
                </b-form>
              </validation-observer>
            </b-modal>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-end mb-2 mb-md-0"
          >
            <b-button
              variant="primary"
              @click="applyGetPointsFocauxListAction()"
            >
              <div v-if="isPointsFocauxListLoading">
                <span> Chargement ... </span>
                <b-spinner small />
              </div>
              <span
                v-else
                class="text-nowrap font-medium-1"
              >Actualiser</span>
            </b-button>
          </b-col>
        </b-row>
        <vue-good-table
          class="mt-2"
          :columns="columns"
          :rows="rows"
          :rtl="direction"
          :is-loading="isPointsFocauxListLoading"
        >
          <template slot="emptystate">
            <div class="text-center text-info">
              <span> {{ isPointsFocauxListLoading ? "" : "Liste vide" }}</span>
            </div>
          </template>

          <template slot="loadingContent">
            <div class="text-center text-info my-2">
              <b-spinner class="align-middle text-info" />
            </div>
          </template>

          <template
            slot="table-row"
            slot-scope="props"
          >
            <div
              v-if="props.column.field == 'town_name'"
              class="align-middle text-center"
            >
              <span class="d-block font-small-5 text-info text-nowrap">
                {{ props.row.town_name }}
              </span>
            </div>
            <div
              v-if="props.column.field == 'responsable_name'"
              class="align-middle text-center"
            >
              <span class="d-block font-small-5 text-info text-nowrap">
                {{ props.row.responsable_name }}
              </span>
            </div>
            <div
              v-if="props.column.field == 'email'"
              class="align-middle text-center"
            >
              <span class="d-block font-small-5 text-info text-nowrap">
                {{ props.row.email }}
              </span>
            </div>
            <div
              v-else-if="
                props.column.field == 'action' &&
                  props.row.responsable_name !== 'YLOMI'
              "
            >
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="25"
                      class="text-info align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item
                    :id="`update${props.row.id}`"
                    v-b-modal.modal-update-offer-type
                    class="cursor-pointer itemActions"
                    @click="showUpdatePointFocalModal(props.row)"
                  >
                    <feather-icon
                      icon="EditIcon"
                      class="text-info mr-1"
                      size="25"
                    />
                    <span>Modifié</span>
                  </b-dropdown-item>

                  <b-dropdown-item
                    :id="`delete${props.row.id}`"
                    class="cursor-pointer itemActions"
                    @click="applyDeleteOnePointFocalAction(props.row.id)"
                  >
                    <feather-icon
                      icon="TrashIcon"
                      class="text-info mr-1"
                      size="25"
                    />
                    <span>Supprimé</span>
                  </b-dropdown-item>

                  <b-dropdown-item
                    v-if="props.row.town_name !== 'YLOMI'"
                    :id="`details${props.row.id}`"
                    class="cursor-pointer itemActions"
                    @click="showPointFocalWalletDetails(props.row.id)"
                  >
                    <feather-icon
                      icon="EyeIcon"
                      class="text-info mr-1"
                      size="25"
                    />
                    <span>Détails du portefeuille</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </div>
          </template>
        </vue-good-table>
        <div class="d-flex justify-content-between mt-2 flex-wrap">
          <b-button
            :disabled="metaData.prev_page_url == null"
            variant="outline-secondary"
            @click="applyLoadMorePointsFocauxAction(metaData.prev_page_url)"
          >
            <span class="text-white text-nowrap font-medium-2">Page précédente</span>
          </b-button>

          <div>
            <span class="text-white font-medium-2">{{ metaData.current_page }} sur
              {{ metaData.last_page }} pages</span>
          </div>
          <div>
            <span class="text-white font-medium-2">Total :{{ total }}</span>
          </div>
          <b-button
            variant="outline-secondary"
            :disabled="metaData.next_page_url == null"
            @click="applyLoadMorePointsFocauxAction(metaData.next_page_url)"
          >
            <span class="text-nowrap font-medium-1 text-white">Page suivante</span>
          </b-button>
        </div>
      </div>
    </b-card>
  </div>
  <div v-else>
    <not-authorized />
  </div>
</template>

<script>
import {
  // BTab,
  BCard,
  BRow,
  BForm,
  BFormSelect,
  BCol,
  BFormInput,
  BFormGroup,
  // BFormInvalidFeedback,
  BButton,
  // BMedia,
  BModal,
  BDropdown,
  BDropdownItem,
  BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum } from '@validations'
import { mapActions, mapGetters } from 'vuex'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'OfferTypesList',
  components: {
    // BTab,
    VueGoodTable,
    ValidationProvider,
    ValidationObserver,
    BModal,
    // BTabs,
    BFormGroup,
    BCard,
    BRow,
    BForm,
    BCol,
    BFormInput,
    BButton,
    BDropdown,
    BDropdownItem,
    BSpinner,
    BFormSelect,
    vSelect,
  },
  data() {
    return {
      rows: [],
      total: '',
      payloadFilter: {
        pf_id: '',
      },
      isAddPointFocalLoading: false,
      isReLoadFilterDataPointFocalWithoutPagination: false,
      isUpdatePointFocalLoading: false,
      pointFocalId: null,
      payload: {
        town_name: '',
        responsable_name: '',
        is_center: null,
        email: '',
      },
      pointFocalOptions: [],
      required,
      alphaNum,
      columns: [
        {
          label: 'Ville',
          field: 'town_name',
        },
        {
          label: 'Responsable',
          field: 'responsable_name',
        },
        {
          label: 'Email',
          field: 'email',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('offerType', ['getPointFocaux', 'getPointFocauxWithoutPagination']),
    ...mapGetters(['isPointsFocauxListLoading', 'isPointFocauxWihoutPagination']),
    ...mapGetters('auth', ['getUser']),

    metaData() {
      const meta = {
        prev_page_url: '',
        next_page_url: '',
        current_page: '',
        last_page: '',
        current_page_url: '',
      }
      if (this.getPointFocaux != null) {
        meta.prev_page_url = this.getPointFocaux.prev_page_url
        meta.next_page_url = this.getPointFocaux.next_page_url
        meta.current_page = this.getPointFocaux.current_page
        meta.last_page = this.getPointFocaux.last_page
        meta.current_page_url = `${this.getPointFocaux.path}?page=${this.getPointFocaux.current_page}`
        this.total = this.getPointFocaux.total
      }
      return meta
    },
  },
  watch: {
    payloadFilter: {
      handler(newValue, oldValue) {
        const keys = Object.keys(newValue)
        keys.forEach(key => {
          if (newValue[key] == null) {
            newValue[key] = ''
            console.log(this.payloadFilter)
          }
        })
      },
      deep: true,
    },
    isPointFocauxWihoutPagination(val) {
      if (val == false) {
        this.getPointFocauxWithoutPagination.forEach(element => {
          this.pointFocalOptions.push({
            id: element.id,
            name: element.responsable_name,
          })
        })
      }
    },

    isPointsFocauxListLoading(val) {
      if (val === false) {
        this.rows = this.getPointFocaux.data
      }
    },
  },

  created() {
    this.getPointFocaux !== null ? (this.rows = this.getPointFocaux.data) : null

    if (this.getPointFocauxWithoutPagination.length != 0) {
      this.getPointFocauxWithoutPagination.forEach(element => {
        this.pointFocalOptions.push({
          id: element.id,
          name: element.responsable_name,
        })
      })
    }
  },

  methods: {
    ...mapActions('offerType', [
      'getPointFocalAction',
      'loadMorePointFocauxAction',
      'addNewPointFocalAction',
      'updateOnePointFocalAction',
      'deleteOnePointFocalAction',
      'filterPointFocalAction',
    ]),

    applyFilterPointFocauxAction() {
      const allIsNUl = Object.values(this.payloadFilter).every(el => el === '')
      if (allIsNUl) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Warning',
              icon: 'CheckIcon',
              text: 'Veuillez sélectionner au moins un champ du filtre.',
              variant: 'warning',
            },
          },
          {
            position: 'top-center',
          },
        )
      } else {
        this.isReLoadFilterDataPointFocalWithoutPagination = true
        this.rows = []
        this.filterPointFocalAction(this.payloadFilter)
          .then(response => {
            this.isReLoadFilterDataPointFocalWithoutPagination = false
            this.rows = this.getPointFocaux
            this.total = this.rows.length
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    applyGetPointsFocauxListAction() {
      this.$store.commit('SET_IS_POINTS_FOCAUX_LIST_LOADING', true)
      this.getPointFocalAction()
        .then(() => {
          this.$store.commit('SET_IS_POINTS_FOCAUX_LIST_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_POINTS_FOCAUX_LIST_LOADING', false)
        })
    },
    showPointFocalWalletDetails(id) {
      this.$router.push({
        name: 'point-focal-wallet',
        params: { id },
      })
    },

    applyLoadMorePointsFocauxAction(url) {
      this.$store.commit('SET_IS_POINTS_FOCAUX_LIST_LOADING', true)
      this.rows = []
      this.loadMorePointFocauxAction(url)
        .then(() => {
          this.$store.commit('SET_IS_POINTS_FOCAUX_LIST_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_POINTS_FOCAUX_LIST_LOADING', false)
        })
    },
    showUpdatePointFocalModal(data) {
      this.payload.town_name = data.town_name
      this.payload.responsable_name = data.responsable_name
      this.payload.is_center = data.is_center ? 1: 0
      this.payload.email = data.email
      this.pointFocalId = data.id
    },

    hideModal(data, modal) {
      data.town_name = ''
      data.responsable_name = ''
      this.$bvModal.hide(modal)
    },

    applyAddNewPointFocalAction() {
      this.$refs.addNewRoleForm.validate().then(success => {
        if (success) {
          this.isAddPointFocalLoading = true
          this.addNewPointFocalAction(this.payload)
            .then(response => {
              this.isAddPointFocalLoading = false
              this.applyLoadMorePointsFocauxAction(
                this.metaData.current_page_url,
              )
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Succès',
                    icon: 'CheckIcon',
                    text: response.message,
                    variant: 'success',
                  },
                },
                {
                  position: 'top-center',
                },
              )
              this.$bvModal.hide('modal-new-offer-type')
              this.payload = {
                town_name: '',
                responsable_name: '',
              }
            })
            .catch(error => {
              console.log(error)
              this.isAddPointFocalLoading = false
            })
        }
      })
    },
    applyUpdateOnePointFocalAction() {
      this.$refs.updatePointFocalForm.validate().then(success => {
        if (success) {
          this.isUpdatePointFocalLoading = true
          this.updateOnePointFocalAction({
            payload: this.payload,
            id: this.pointFocalId,
          })
            .then(response => {
              this.isUpdatePointFocalLoading = false
              console.log('metaData', this.metaData)

              this.applyLoadMorePointsFocauxAction(
                this.metaData.current_page_url,
              )
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Succès',
                    icon: 'CheckIcon',
                    text: response.message,
                    variant: 'success',
                  },
                },
                {
                  position: 'top-center',
                },
              )
              this.$bvModal.hide('modal-update-offer-type')
              this.payload = {
                town_name: '',
                responsable_name: '',
              }
            })
            .catch(error => {
              console.log(error)
              this.isUpdatePointFocalLoading = false
            })
        }
      })
    },
    applyDeleteOnePointFocalAction(id) {
      this.$swal({
        title: 'Suppression',
        text: 'Êtes-vous sûr de vouloir supprimer ce Point Focal ?',
        icon: 'warning',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: 'Non',
        allowOutsideClick: false,
        confirmButtonText: 'Oui',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
        // eslint-disable-next-line no-return-await
        preConfirm: async () => await this.deleteOnePointFocalAction(id),
      })
        .then(async response => {
          console.log(response)
          if (response.value && response.value.status === 200) {
            this.applyLoadMorePointsFocauxAction(
              this.metaData.current_page_url,
            )
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Succès',
                  icon: 'CheckIcon',
                  text: response.value.data.message,
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              },
            )
          }
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
